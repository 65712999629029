// Import dependencies
import React from "react";
import {useStaticQuery, graphql} from "gatsby";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import TitleSection from "../components/sections/titleSection";
import HowItWorksSection from "../components/index/howItWorksSection";
import HeaderSeparator from "../components/separators/headerSeparator";
import SectionSeparator from "../components/separators/sectionSeparator";
import {PageContainer} from "../styled-components/UILibrary";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";

// Import styles
import "./index.css";


/*
    Index page component
*/
const IndexPage = () => {
    // GraphQL image query
    const images = useStaticQuery(graphql`
    query {

      step2: file(relativePath: { eq: "step111.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 672, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      step3: file(relativePath: { eq: "how-it-works-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 672, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      step4: file(relativePath: { eq: "how-it-works-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 672, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      step5: file(relativePath: { eq: "how-it-works-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 672, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

    return (
        <Layout>
            <CategoryDrawer/>
            <SEO
                title="Home"
                description="On-demand buy-back and recycling for electronic gadgets, appliances, and office equipment."
            />

            <SearchBar/>

            {/* Page content */}
            <PageContainer className="howitwork_page_container">
                {/* How it works section */}
                <HowItWorksSection
                    step1="Step1"
                    step2="Step2"
                    step3="Step3"
                    step4="Step4"
                    step5="Step5"
                />
                <div style={{height: "150px"}}>
                    <SectionSeparator/>
                </div>
            </PageContainer>
        </Layout>
    );
};

export default IndexPage;
