// Import dependencies
import React from "react"
import Img from "gatsby-image"
import { Row, Col } from "antd"
import ReactResizeDetector from "react-resize-detector"
import {navigate} from "gatsby";

// Import components
import Accordion from "../accordion/accordion"
import TitleSection from "../sections/titleSection"

// Import styles
import "./how-it-works.css"

// Import images
import Step1Img from "../../assets/images/hiw_step1.png";
import Step2Img from "../../assets/images/hiw_step2.png";
import Step3Img from "../../assets/images/hiw_step3.png";
import Step4Img from "../../assets/images/hiw_step4.png";
import Step5Img from "../../assets/images/hiw_step5.png";

import { STRIPE_CONSTANT, DEPLOY_TYPE } from "../../constants/stripe"

/*
    How it works step component from the index page
*/
export default class HowItWorks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentWidth: 0,
    }
    this.onResize = this.onResize.bind(this)
  }

  onResize = width => {
    this.setState({ currentWidth: width })
  }

  render() {
    return (
      <ReactResizeDetector handleWidth onResize={this.onResize}>
        <Row
          className="hiw__container"
          gutter={this.state.currentWidth > 644.28125 ? 40 : 0}
          align="middle"
          type="flex"
          style={this.props.reverse ? { flexDirection: "row-reverse" } : null}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="hit_text">
            <div className="hit__title-container-mobile">
            <div className="pricing_page_title how_it_work_title" style={{textAlign: "left"}}>
              <h1>{this.props.title}</h1>
              {this.props.paragraph == 1 && <p>It’s just like online shopping, except you find the item you want to SELL to us by searching for its <b>type</b> (e.g. book), <b>brand</b> (e.g. Nike), or <b>model</b> (e.g. iPhone X).</p>}

              {this.props.paragraph == 2 && <p>Tell us the basics about what you’re recycling, and our algorithms will fetch a reliable estimate for the recyclable value of your item. </p>}

              {this.props.paragraph == 3 && <p>Choose a date, time, and place for us to pick up your items. You can book a <b>guaranteed 1-hour time slot</b> for as soon as next-day, and no need to sort items!</p>}

              {this.props.paragraph == 4 && <p>We’ll take your things  to our HQ and double-check them for accuracy. A whopping <b>97% of orders</b> are approved without a quote adjustment.</p>}

              {this.props.paragraph == 5 && <p>You’ll get paid on the 3rd workday after we collect your items. Once approved, 100% of orders are paid on time via <b>PayNow</b>, <b>PayPal</b>, or <b>bank transfer</b>.</p>}

              <div className="how_it_work_links">
                { this.props.links.map((link,i) => {
                    if (link.link == STRIPE_CONSTANT[DEPLOY_TYPE]["URL"] + "pricing") {
                      return <span key={i} onClick={() => navigate("/pricing")}>{link.text}<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                      </svg>
                      </span>;
                    } else {
                      return <a href={link.link}><span key={i}>{link.text}<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                      </svg>
                      </span></a>;
                    }
                  }

                )}
              </div>

            </div>
            </div>
            {this.props.accordion && this.state.currentWidth > 644.28125 ? (
              <Accordion
                title="How do I get paid?"
                content="During checkout you can choose to get paid through Pay Now, Grab Pay, Apple Pay, Google Pay, PayPal, or bank transfer. You can also get paid with a custom electronic gift voucher for Starbucks, Sephora, or Scoot, which will be sent to you by email."
                styles={{ marginTop: 20, maxWidth: 440, width: "100%" }}
              />
            ) : null}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="hit_img"
          >


          {this.props.image == "Step1" ? (<img className="how-it-work-img" src={Step1Img} />) : null}
          {this.props.image == "Step2" ? (<img className="how-it-work-img" src={Step2Img} />) : null}
          {this.props.image == "Step3" ? (<img className="how-it-work-img" src={Step3Img} />) : null}
          {this.props.image == "Step4" ? (<div className="how_it_work_img_special"><img src={Step4Img} style={{height: "auto", width: 622.06, left: -59, top: -72}} /></div>) : null}
          {this.props.image == "Step5" ? (<div className="how_it_work_img_special"><img src={Step5Img} style={{height: "auto", width: 622.06, left: -59, top: -72}} /></div>) : null}


          </Col>

          {this.props.accordion && this.state.currentWidth < 644.28125 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: 20,
              }}
            >
              <Accordion
                title="How do I get paid?"
                content="During checkout you can choose to get paid through Pay Now, Grab Pay, Apple Pay, Google Pay, PayPal, or bank transfer. You can also get paid with a custom electronic gift voucher for Starbucks, Sephora, or Scoot, which will be sent to you by email."
                styles={{ marginTop: 20, maxWidth: 440, width: "100%" }}
              />
            </div>
          ) : null}
        </Row>
      </ReactResizeDetector>
    )
  }
}
